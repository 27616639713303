import type { PublicMedia } from "~~/modules/sales/src";
import type { BaseModel } from "./model";

export enum MediaType {
  IMAGE = "image",
  VIDEO = "video",
}

export interface Media extends BaseModel, PublicMedia {
  id: string;
  type: MediaType;
  src: string;
  description?: string;
  // thumb?: string
  alt?: string;
  height?: number;
  width?: number;
}

export type MediaLike = Media | PublicMedia;
